/* eslint-disable @typescript-eslint/no-explicit-any */
import { Ticket } from "@/interfaces/ticket.interface";
import axios from "@/services/base.service";
import { AxiosResponse } from "axios";

const resource = "public";
//GET TICKET INFORMATION
export const GetTicketInformation = async (
  ticketId: string
): Promise<AxiosResponse<{ data: Ticket }>> => {
  return await axios.get(`${resource}/${ticketId}/ticket`);
};

//SEND DATA FOR QR
export const SendDataForAssistan = async (
  data: any
): Promise<AxiosResponse<object>> => {
  return await axios.post(`${resource}/assistants`, data);
};
