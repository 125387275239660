/* eslint-disable @typescript-eslint/no-explicit-any */
// import Vue from "vue";
import router from "@/router";

const sellerState = {
  state: () => ({
    eventSelected: null
  }),
  mutations: {
    setEventSelected(state: any, eventSelected: number): void {
      state.eventSelected = eventSelected;
    }
  },
  actions: {
    setEventSelected({ commit }: any, eventId: number): void {
      commit("setEventSelected", eventId);
      router.push("/seller/menu");
    }
  },
  getters: {}
};
export default sellerState;
