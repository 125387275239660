/* eslint-disable @typescript-eslint/no-explicit-any */
import base from "@/services/base.service";
import { AxiosResponse } from "axios";

import axios from "axios";

const scanURL = process.env.VUE_APP_SCAN_API;

const token = localStorage.getItem("access_token");

const scanApi = axios.create({
  baseURL: scanURL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    authorization: "Bearer " + token
  }
});

const resource = "recepcionist";
//GET TICKET INFORMATION
export const GetAssinstant = async (
  assistantId: string
): Promise<AxiosResponse<object>> => {
  return await scanApi.get(`${scanURL}/scan/${assistantId}`);
};

//SEND DATA FOR QR
export const UpdateAssistantStatus = async (
  data: any
): Promise<AxiosResponse<object>> => {
  return await scanApi.put(`${scanURL}/assistants/verify-assistant`, data);
};
