/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
import Vue from "vue";
import {
  GetTicketInformation,
  SendDataForAssistan
} from "@/services/public.service";
import { AxiosResponse } from "axios";
import { UserI } from "@/interfaces/user.interface";
import router from "@/router";
import { getFirstError } from "@/helpers/validation";

const userState = {
  state: () => ({
    userInfo: {
      fullname: localStorage.getItem("fullname"),
      id: localStorage.getItem("id")
    },
    publicUserInfo: {},
    isPublicFormDisabled: true,
    affidavit: {
      q1: "Nombre",
      q2: "Apellido",
      q3: "DNI",
      q4: "Lugar de residencia",
      q5: "Teléfono celular",
      q6: "Empresa donde trabaja (Domicilio, Teléfono)",
      q7: "¿Ha viajado al exterior en los ultimos 14 días?",
      q8: "¿Donde?",
      q9: "¿Ha estado en contacto con viajeros?",
      q10: "¿Cuando?",
      symtomsQuestion: "¿Actualmente usted presenta alguno de estos sintomas?",
      q11: "Fiebre",
      q12: "Tos",
      q13: "Dolor de garganta",
      q14: "Problemas para respirar",
      q15: "Ha perdido el gusto y/o el olfato",
      q16: "Ha tenido manchas en su cuerpo",
      q17: "Sintomas gastrointestinales",
      q18:
        "¿En su familia hubo personas que han tenido algunos de estos síntomas en estos últimos 14 días?"
    },
    ticket: {},
    product: {},
    qrData: "",
    assistantFullname: "",
    assistantDNI: "",
    assistantGender: ""
  }),
  mutations: {
    setUserName(state: any, name: string): void {
      state.userName = name;
    },
    setUserInfo(state: any, userInfo: any): void {
      state.userInfo = userInfo;
    },
    setPublicUserInfo(state: any, userInfo: any): void {
      state.publicUserInfo = userInfo;
    },

    setTicketInfo(state: any, ticket: any): void {
      state.ticket = ticket;
    },

    setProductInfo(state: any, product: any): void {
      console.log(product);
      state.product = product;
    },

    setIsPublicFormDisabled(state: any, isDisabled: boolean): void {
      state.isPublicFormDisabled = isDisabled;
    },
    setQRData(state: any, qrData: any): void {
      state.qrData = qrData;
    },
    setAssistantFullname(state: any, assistantFullname: any): void {
      state.assistantFullname = assistantFullname;
    },
    setAssistantDNI(state: any, assistantDNI: any): void {
      state.assistantDNI = assistantDNI;
    },
    setAssistantGender(state: any, assistantGender: any): void {
      state.assistantGender = assistantGender;
    }
  },
  actions: {
    setUserName({ commit }: any, name: string): void {
      commit("setUserName", name);
    },
    setUserInfo({ commit }: any, userInfo: UserI): void {
      commit("setUserInfo", userInfo);
    },
    setPublicUserInfo({ commit }: any, userInfo: UserI): void {
      commit("setPublicUserInfo", userInfo);
    },
    setIsPublicFormDisabled({ commit }: any, isDisabled: boolean): void {
      commit("setIsPublicFormDisabled", isDisabled);
    },

    async getTicketInfo({ commit }: any, ticketId: string): Promise<void> {
      const loader = Vue.$loading.show();
      try {
        const response: AxiosResponse = await GetTicketInformation(ticketId);
        const {
          data: { data },
          status
        } = response;
        if (status === 200) {
          commit("setTicketInfo", data);
          loader.hide();
        }
      } catch (error) {
        loader.hide();
      }
    },

    async sendAssistantsInfo(
      { commit }: any,
      assistantsData: any
    ): Promise<void> {
      const loader = Vue.$loading.show();
      try {
        const response: AxiosResponse = await SendDataForAssistan(
          assistantsData
        );
        const { data, status } = response;
        if (status === 201) {
          console.log(response);
          commit("setProductInfo", data.data.product);
          commit("setQRData", data.data.qr_code);
          commit("setAssistantFullname", data.data.fullname);
          commit("setAssistantDNI", data.data.dni);
          commit("setAssistantGender", data.data.gender);
          router.push("/generate-qr");
          loader.hide();
        }
      } catch (error) {

        const e = getFirstError(error.response.data.errors);

        if (e) {
          Vue.$toast.error(e);
        } else {
          Vue.$toast.error("Ha ocurrido un error desconocido. Por favor, intenta nuevamente!");
        }

        loader.hide();
        console.info(error);
      }
    }
  },
  getters: {
    ticketHasQuotas: (state: any) => {
      const maxQuote = state.ticket?.quota;
      const currentQuote = state.ticket?.assistants_count;
      return currentQuote < maxQuote;
    },
    isLinkValid: (state: any) => {
      return state.ticket.id ? true : false;
    }
  }
};
export default userState;
