/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
import { Login, Register } from "@/services/auth.service";
import router from "@/router";
import Vue from "vue";
import { LoginI, RegisterI } from "@/interfaces/user.interface";
import { AxiosResponse } from "axios";
import baseApi from "@/services/base.service";
import { UserTypes } from "@/helpers/constants";

const authState = {
  state: () => ({
    login: {},
    register: {},
  }),
  mutations: {
    /**
     * @params name - product name to set selected product card.
     * @remarks this refers to product cards in buy and sell components
     *   */
    setLogin(state: any, payload: LoginI): void {
      state.login = payload;
    },
    setRegister(state: any, payload: RegisterI): void {
      state.register = payload;
    },
  },
  actions: {
    async setLogin({ commit, dispatch }: any, payload: LoginI): Promise<any> {
      const loader = Vue.$loading.show();

      try {
        const response: AxiosResponse = await Login(payload);

        const { data, status } = response;

        const { access_token, user, redirect } = data;
        const { fullname, email, role, id, active, username } = user;
        console.log(redirect);
        if (status === 200) {
          loader.hide();
          baseApi.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${access_token}`;

          localStorage.setItem("access_token", access_token);
          localStorage.setItem("fullname", fullname);
          localStorage.setItem("user_id", id);
          localStorage.setItem("username", fullname);

          Vue.$toast.success(`Bienvenid@ ${fullname}`);
          commit("setLogin", { fullname, email });

          dispatch("setUserName", fullname);
          dispatch("setUserInfo", {
            fullname: fullname,
            email,
            role,
            id,
            active,
            username,
            roles: role.name
          });

          if (role.name === UserTypes.RECEPCIONIST) {
            window.location.href =process.env.VUE_APP_SCANNER
          } else if (role.name === UserTypes.SUPERADMIN ) {
            router.push("/comission-report")
            return;
          } 
          router.push("/");

        } else {
          Vue.$toast.error(
            `Lo sentimos ${fullname}, algo ha salido mal, intenta nuevamente`
          );
          throw data;
        }
      } catch (error) {
        console.error(error);
        loader.hide();
        Vue.$toast.error(`Credenciales incorrectas`);
      }
    },
    async setRegister(
      { commit, dispatch }: any,
      payload: RegisterI
    ): Promise<any> {
      const loader = Vue.$loading.show();

      try {
        const { data }: AxiosResponse = await Register(payload);
        if (data.status === 200) {
          commit("setRegister", payload);
          loader.hide();
          dispatch("setLogin", {
            email: payload.email,
            pass: payload.pass,
          });
          // router.push("/login");
        } else {
          loader.hide();
          Vue.$toast.error(
            `We sorry ${payload.fullname}, someting goes wrong, please try again`
          );
        }
      } catch (error) {
        loader.hide();
        console.error(error);
        Vue.$toast.error(
          `We sorry ${payload.fullname}, someting goes wrong, please try again`
        );
      }
    },
  },
};
export default authState;
