/**
 * Retrieves the first error message from the given errors object.
 * 
 * @param errors - The errors object containing error messages for different keys.
 * @returns The first error message, or null if no errors are found.
 */
export function getFirstError(errors: { [key: string]: string[] }): string | null {
  let firstErrorKey = Object.keys(errors)[0];
  return errors[firstErrorKey] ? errors[firstErrorKey][0] : null;
}
