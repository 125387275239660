export enum UserTypes {
  SELLERS = "sellers",
  RECEPCIONIST = "recepcionist",
  GUEST = "guest",
  SUPERADMIN = "super"
}

export enum ErrorMessages {
  COMMON = "Un error ha ocurrido, por favor intente de nuevo.",
  SESION_EXPIRED = "Su sesión ha expirado, por favor inicie sesión nuevamente.",
  MAX_PERSONS = "La cantidad de personas es mayor que la cantidad máxima permitida en el producto.",
  COMMOM_FIELD_REQUIRED = "El campo es requerido.",
  MAX_QUOTE = "No puede superar la cuota maxima",
  MIN_ZERO = "No puede ser menor a 0",
}
