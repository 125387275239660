import Vue from "vue";
import Vuex from "vuex";
import appState from "@/store/modules/appState";
import authState from "@/store/modules/authState";
import receptionistState from "./modules/receptionistState";
import sellerState from "./modules/sellerState";
import userState from "./modules/userState";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    appState,
    authState,
    sellerState,
    receptionistState,
    userState
  }
});
