/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
import Vue from "vue";
import { AxiosResponse } from "axios";
import {
  GetAssinstant,
  UpdateAssistantStatus
} from "@/services/receptionist.service";
import router from "@/router";
const receptionistState = {
  state: () => ({
    assistant: {},
    isVerified: null,
    isPayed: null,
    isValid: null,
    hasPermission: null
  }),
  mutations: {
    setAssistant(state: any, assistant: any): void {
      state.assistant = assistant;
    },
    setHasPermission(state: any, hasPermission: boolean): void {
      state.hasPermission = hasPermission;
    },
    setIsQRValid(state: any, isQRValid: boolean): void {
      state.isValid = isQRValid;
    },
    setIsPayed(state: any, isPayed: boolean): void {
      state.isPayed = isPayed;
    },
    setIsVerified(state: any, isVerified: boolean): void {
      state.isVerified = isVerified;
    }
  },
  actions: {
    async getAssistant({ commit }: any, assistantId: string): Promise<void> {
      console.log(assistantId);

      const loader = Vue.$loading.show();
      try {
        const response: AxiosResponse = await GetAssinstant(assistantId);
        const { data, status } = response;

        const isVerified = data.verified;
        const isPayed = data.payment_status;

        if (status == 200) {
          commit("setAssistant", data);
          commit("setIsVerified", isVerified);
          commit("setIsPayed", isPayed);
          loader.hide();
          router.push("/ticket-info");
        } else if (status == 401) {
          commit("setHasPermission", false);
          loader.hide();
          router.push("/qr-status");
          return;
        } else if (status == 404) {
          commit("setIsQRValid", false);
          loader.hide();
          router.push("/qr-status");
        }
      } catch (error) {
        commit("setIsQRValid", false);
        loader.hide();
        router.push("/qr-status");
      }
    },
    // eslint-disable-next-line no-empty-pattern
    async updateAssistantStatus({}: any, newStatus: any): Promise<void> {
      const loader = Vue.$loading.show();
      try {
        const response: AxiosResponse = await UpdateAssistantStatus(newStatus);
        const { status } = response;

        if (status === 200) {
          Vue.$toast.success(`Ticket verificado!`);
          loader.hide();
          router.push("/");
        }
      } catch (error) {
        Vue.$toast.error(`Ha ocurrido un error intentanto verificar`);
        loader.hide();
      }
    }
  }
};
export default receptionistState;
