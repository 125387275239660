// @ts-nocheck
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
Vue.config.productionTip = false;
import VueAnimated from "@codekraft-studio/vue-animated";
import Vuelidate from "vuelidate";
import VuePageTransition from "vue-page-transition";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import SequentialEntrance from "vue-sequential-entrance";
import "vue-sequential-entrance/vue-sequential-entrance.css";
import VueQrcodeReader from "vue-qrcode-reader";
// import icons from "v-svg-icons";
import VueQRCodeComponent from "vue-qrcode-component";
import { VueMaskDirective } from "v-mask";

import VueHtml2Canvas from "vue-html2canvas";

// Import Bootstrap and BootstrapVue CSS files (order is important)
import { BootstrapVue } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";

// Google Analytics
import VueAnalytics from "vue-analytics";

// Switch button
import ToggleButton from "vue-js-toggle-button";

import "bootstrap-vue/dist/bootstrap-vue.css";

// Google analytics
Vue.use(VueAnalytics, {
  id: "G-V2FK1SS05N"
});

Vue.use(ToggleButton);

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);

Vue.component("qr-code", VueQRCodeComponent);
Vue.directive("mask", VueMaskDirective);

// Vue.component("p-icon", icons);
Vue.use(VueHtml2Canvas);
Vue.use(SequentialEntrance);
Vue.use(VueQrcodeReader);
Vue.use(Toast, {
  position: "bottom-center",
  transition: "Vue-Toastification__fade",
  maxToasts: 3,
  newestOnTop: true,
  timeout: 5000,
  draggable: true,
  draggablePercent: 0.6
});
Vue.use(
  Loading,
  {
    // props
    color: "#E4445B",
    loader: "bars",
    width: 64,
    height: 64,
    backgroundColor: "#F5F5F5",
    opacity: 0.5
  },
  {
    // slots
  }
);
Vue.use(VuePageTransition);
Vue.use(Vuelidate);
Vue.use(VueAnimated, {
  functional: true
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
