import {
  LoginI,
  LoginResponseI,
  RegisterI,
  UserI
} from "@/interfaces/user.interface";
import { ResponseI } from "@/interfaces/response.interface";
import axios from "@/services/base.service";
import { AxiosResponse } from "axios";

/**
 *@remarks
 * this function can be changed
 *@param name - string user name
 *@param password - string user password
 *@param email - string user email
 *@returns an object with jwt token and user info or error messages
 **/

const login = "/auth/login";
const register = "register";

export const Register = async (
  userRegisterData: RegisterI
): Promise<AxiosResponse<ResponseI<UserI>>> => {
  return await axios.post(register, userRegisterData);
};

/**
 *@remarks
 * this function can be changed
 *@param identifier - string user or email
 *@param password - user password
 *@returns an object with jwt token and user info or error messages
 **/
export const Login = async (
  userLoginData: LoginI
): Promise<AxiosResponse<LoginResponseI>> => {
  return await axios.post<LoginResponseI>(login, userLoginData);
};
